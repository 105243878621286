@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

body{
  background-color:#111b21;
  overflow: hidden;
  
}

#root,#top{
  overflow: hidden;
}


.projectsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  overflow-y: scroll;
 
  height: 92%;
  scrollbar-width: thin;
  scrollbar-color: gray transparent;
  position: relative;

}

.projectComponent{
  border-radius: 20px;
  margin: 20px;
  z-index: 0;
  padding: 20px;
  width: auto;
  background-color: #202c33;
  display: flex;

}
